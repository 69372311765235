import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  static targets = ["unit"]
  trackerModuleInst = trackerModuleInst

  initialize() {
    console.log("[freestarad::initialize] this.pixel", this.trackerModuleInst)
  }
  async connect() {
    console.log("[freestarad::connect] this.pixel", this.trackerModuleInst)
    await this.perform()
  }
  async perform() {
    try {
      const unit = this.unitTarget
      console.log("[freestarad::perform] unit", unit)
      const slotName = unit.getAttribute("data-ad-id")
      console.log("slot", slotName)
      unit.setAttribute("id", slotName)
      console.log("[freestarad::perform] unit", unit)
      const freestarInitScript = document.createElement("script")
      freestarInitScript.type = "text/javascript"
      freestarInitScript.text = `
        console.log("[freestarad::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          if (typeof freestar === typeof undefined) {
            var adUnit = document.querySelector("#${slotName}")
            console.log('adUnitinside of script tag', adUnit)
            if (adUnit) adUnit.remove()
          } else {
            if(freestar && freestar.queue) {
              console.log("[freestarad::perform] inside of script tag on place and ready to use", freestar)
              freestar.queue.push(function() {
                freestar.config.enabled_slots.push({
                  placementName: "${slotName}",
                  slotId: "${slotName}"
                });
              }) 
              freestar.queue.push(function() {
                freestar.newStickyFooter("ushousinghelper_Sticky_Footer_2");
              });
            }
          }
        }
      `
      if (this.unitTarget) {
        this.unitTarget.insertAdjacentElement("afterend", freestarInitScript)
      }
    } catch (e) {
      console.log("e", e)
    }
  }
}
