import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['content', 'toggle']
  initialize(){
    console.log('[AccordionController::initialize]', true)

  }

  connect(){
    console.log('[AccordionController::connect]', true)
    this.contentTarget.classList.add('!hidden')
  }

  toggleOpen = (e) => {
    e.preventDefault()
    this.contentTarget.classList.toggle('!hidden')
    this.updateToggle()
  }

  updateToggle() {
    const isOpen = !this.contentTarget.classList.contains('!hidden');
    const minusPath = "M6 12h12m-12 0v0";
    const plusPath = "M12 6v6m0 0v6m0-6h6m-6 0H6";

    const svgPath = this.toggleTarget.querySelector('path')
    if (svgPath)
      svgPath.setAttribute('d', isOpen ? minusPath : plusPath);

    if (isOpen) {
      this.toggleTarget.classList.add('toggleActive');
    } else {
      this.toggleTarget.classList.remove('toggleActive');
    }
  }
}




