import ApplicationController from "@/controllers/application_controller"
import { LinkModule } from "@/modules/link_module"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

const linkModuleInst = new LinkModule()
export default class extends ApplicationController {
  trackerModule = new EventTrackerModule(ahoy)
  static values = { currentTabUrl: String, newTabUrl: String, target: String }
  linkModule = linkModuleInst

  initialize() {}

  connect() {}

  interceptClickWithRedirect(event) {
    event.preventDefault()
    console.log("[LinkRedirectController::interceptClickWithRedirect]", event)
    const target = this.targetValue
    const newTabUrl = this.newTabUrlValue === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.newTabUrlValue
    const url = this.currentTabUrlValue === "saved_redirect" ? window.localStorage.getItem("savedRedirect") : this.currentTabUrlValue
    console.log("url", url)
    console.log("target", target)
    console.log("newTabUrl", newTabUrl)
    this.trackRedirFire()
    if (newTabUrl && newTabUrl.length > 0) {
      this.trackNewTabFire()
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(newTabUrl),
        true
      )
    }
    if (url && url.length > 0) {
      const newTab = target === "_blank"
      this.trackCurrentTabFire()
      this.linkModule.performRedirect(
        this.linkModule.addCurrentParamsToUrl(url),
        newTab
      )
    }
  }
  trackRedirFire() {
    this.trackerModule.track("link_redirect_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href,
    })
  }
  trackNewTabFire() {
    this.trackerModule.track("link_redirect_new_tab_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href,
    })
  }
  trackCurrentTabFire() {
    this.trackerModule.track("link_redirect_current_tab_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href,
    })
  }
}
