import ApplicationController from "@/controllers/application_controller"
// import { EventTrackerModule } from "@/modules/event_tracker_module"
// import ahoy from "ahoy.js"
// const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  static targets = ["placeholder"]
  static values = {
    redirectUrl: String,
    externalId: String,
    displayType: String,
  }


  initialize() {
    console.log( "[evenfinancial_wall_placeholder::initialize]")
    this.isPerformable = true
    window.localStorage.setItem('renderEvenFinancialWall', false)
    window.addEventListener('storage', () => {
      const performRender = JSON.parse(window.localStorage.getItem('renderEvenFinancialWall')) && this.isPerformable
      if (performRender) {
        this.perform()
        this.isPerformable = false
      }
    })
  }

  connect() {
    console.log( "[evenfinancial_wall_placeholder::connect]")

  }
  async perform() {
    const componentType= this.displayTypeValue == "evenfinancial_card" ? "Cards" : "Wall"
    try {
      const evenfinancialWallInitScript = document.createElement("script")
      evenfinancialWallInitScript.type = "text/javascript"
      evenfinancialWallInitScript.text = `
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Evenfinancial::${componentType}::Component",
            redirect_url: "${this.redirectUrlValue}",
            external_id: "${this.externalIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const evenfinancial = document.querySelector('[data-controller=evenfinancial-wall-placeholder]')
                  evenfinancial.innerHTML = markup
                })
           
          
        }
      `
      if (this.placeholderTarget) {
        this.placeholderTarget.innerHTML = ""
        this.placeholderTarget.insertAdjacentElement(
          "afterend",
          evenfinancialWallInitScript
        )
      }
    } catch (e) {
      console.log("e", e)
    }
  }
}
