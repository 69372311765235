import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  static values = { eventName: String }
  pixelModule

  initialize() {
    this.pixelModule = trackerModuleInst
  }

  connect() {}

  track() {
    console.log("[EventTrackerController::track] this.eventValueName", this.eventValueName)
    this.pixelModule.track(`[EventTrackerController::track] ${this.eventNameValue}`, {})
  }

  handleClick() {
    console.log("element clicked")
    this.pixelModule.track(`element_click`, {})
  }
}
