import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['trigger', 'floater', 'layout']
  static classes = [ "hidden" ]
  initialize(){
    console.log('[FloaterController::initialize]', true)
    this.isActive = false
    this.currentFloater = null
  }

  connect(){
    console.log('[FloaterController::connect]', true)
  }

  toggleOpen = (e) => {
    if(e && !this.currentFloater) {
      const triggerData = e.currentTarget.dataset
      this.currentFLoater = this.currentFloaterTarget(triggerData.floater)
    }
    if (this.currentFloater) {
      this.layoutTarget.classList.toggle(this.hiddenClass)
      this.currentFloater.classList.toggle(this.hiddenClass)
      if (this.isActive) this.currentFloater = null
      this.isActive = !this.isActive
    }
  }

  setCurrentFloater = (trigger) => {
    this.currentFloater = this.currentFloaterTarget(trigger)
  }

  currentFloaterTarget(triggerKey) {
    return this.floaterTargets.find( m => m.dataset.floater === triggerKey )
  }

}