import ApplicationController from "@/controllers/application_controller"

export default class extends ApplicationController {
  static classes = [ "loading" ]

  initialize(){
  }

  connect(){
  }

  start() {
    if (this.loadingClass && this.loadingClass.length > 0) {
      this.element.classList.add(...this.loadingClasses)
    }
  }

  stop() {
    if (this.loadingClass && this.loadingClass.length > 0) {
      this.element.classList.remove(...this.loadingClasses)
    }
  }
}

