import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  static targets = ["placeholder"]
  static values = {
    externalId: String,
  }
  static trackerModuleInst = trackerModuleInst

  initialize() {
    console.log( "[nextinsure_wall_placeholder::initialize]")
    this.isPerformable = true
    this.externalIdValue = "668893"
    this.instantPerform = window.localStorage.getItem('instantPerformNextinsureWall')
    if (this.instantPerform) {
      return this.perform()
    }
    window.localStorage.setItem('renderNextinsureWall', false)
    window.addEventListener('storage', () => {
      const performRender = JSON.parse(window.localStorage.getItem('renderNextinsureWall')) && this.isPerformable 
      if (performRender) {
        this.perform()
        this.isPerformable = false
      }
    })

  }
  async connect() {
    console.log("[nextinsure_wall_placeholder::connect] this.pixel")
  }
  async perform() {
    try {
      console.log("this.redirectUrlValue", this.redirectUrlValue)
      const placeholder = this.placeholderTarget
      console.log("[nextinsure_wall_placeholder::perform] placeholder", placeholder)
      const nextinsureWallInitScript = document.createElement("script")
      nextinsureWallInitScript.type = "text/javascript"
      nextinsureWallInitScript.text = `
        console.log("[nextinsure_wall_placeholder::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Nextinsure::Wall::Component", 
            external_id: "${this.externalIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const nextinsure = document.querySelector('[data-controller=nextinsure-wall-placeholder]')
                  nextinsure.innerHTML = markup
                })
           
          
        }
      `
      if (this.placeholderTarget) {
        this.placeholderTarget.innerHTML = ""
        this.placeholderTarget.insertAdjacentElement(
          "afterend",
          nextinsureWallInitScript
        )
      }
    } catch (e) {
      console.log("e", e)
    }
  }
}
