import ApplicationController from "@/controllers/application_controller";
import { ResultsStoreModule } from "@/modules/results_store_module";
export default class extends ApplicationController {
  static values = {
    conditions: Array
  }

  initialize(){
    this.matched = false
    this.currentParams = Object.fromEntries(new URLSearchParams(window.location.search))
    this.locationData = JSON.parse(document.body.dataset.sessionLocation || {})
    this.checkConditions()
    console.log('[ParamConditionController::initialize]', this.element)
  }

  connect(){
    console.log('[ParamConditionController::connect]', this.element)
    this.handleMatched()
  }

  async checkConditions(){ 
    console.log('[ParamConditionController::checkConditions]', this.currentParams)
    function mapAsync(array, callbackfn) {
      return Promise.all(array.map(callbackfn));
    }
    
    function filterAsync(array, callbackfn) {
      return mapAsync(array, callbackfn).then(filterMap => {
        return array.filter((value, index) => filterMap[index]);
      });
    }
    const currentParams = this.currentParams

    const isMatchedCondition = async (condition) => {
      let matched = false
      if (condition.checkType === 'zip') {
        matched = await this.checkZip(condition.zipListId, this.locationData.zip)
      } else if (condition.checkType === 'storage') {
        matched = this.handleStorageCondition(condition)
      } else {
        matched = currentParams[condition.name] === condition.value
      }
      return matched
    } 
    this.matched = await filterAsync(this.conditionsValue, isMatchedCondition)
    this.matched = this.matched.length > 0
    this.handleMatched()
  }

  async checkZip (zipListId, clientZip) {
    const url = `/api/v1/zip_lists/${zipListId}/check?zip=${clientZip}`
    return await fetch(url)
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        console.log('DATA:::RES', data)
        return data.valid
      })
  }

  handleStorageCondition = async (condition) => {
    // const qStoreModule = await ResultsStoreModule('questions_results')
    // const qStoreObject = await qStoreModule.getStore("questions_results") || {}
    // if (qStoreObject[condition.name] === condition.value) return true

    window.addEventListener("storage", async (e) => {
      if (!this.element) return
      const qStoreModule = await ResultsStoreModule('questions_results')
      const qStoreObject = await qStoreModule.getStore("questions_results") || {}
      this.matched = qStoreObject[condition.name] === condition.value
      this.handleMatched()
    })
  }

  handleMatched() {
    console.log('HANDLE MATCHED', [this.element, this.matched])
    if (this.matched) {
      const isQuestion = this.element.dataset.controller.includes("chain--question--component")
      this.element.remove()
      if (isQuestion) this.chainController.connectCurrentQuestion()
    }
  }

  get chainController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(".chain"),
      "chain--component"
    )
  }
}