import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['burger', 'menu']
  static classes = [ "active" ]
  initialize(){
    console.log('[BurgerMenuController::initialize]', true)

  }

  connect(){
    console.log('[BurgerMenuController::connect]', true)
  }

  toggleOpen = (e) => {
    e.preventDefault()
    this.burgerTarget.classList.toggle(this.activeClass)
    this.menuTarget.classList.toggle(this.activeClass)
  }
}