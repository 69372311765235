import ApplicationController from "@/controllers/application_controller"
import { EventTrackerModule } from "@/modules/event_tracker_module"
import ahoy from "ahoy.js"

const trackerModuleInst = new EventTrackerModule(ahoy)
export default class extends ApplicationController {
  static targets = ["placeholder"]
  static values = {
    campaignId: String,
  }
  static trackerModuleInst = trackerModuleInst

  initialize() {
    console.log(
      "[transparently_wall_placeholder::initialize]")
    window.localStorage.setItem('renderTransparentlyWall', false)
    window.addEventListener('storage', () => {
      const performRender = JSON.parse(window.localStorage.getItem('renderTransparentlyWall'))
      if (performRender) {
        window.localStorage.setItem('renderTransparentlyWall', false)
        this.perform()
      }
    })
  }

  async perform() {
    try {
      const placeholder = this.placeholderTarget
      console.log("[transparently_wall_placeholder::perform] placeholder", placeholder)
      const transparentlyWallInitScript = document.createElement("script")
      transparentlyWallInitScript.type = "text/javascript"
      transparentlyWallInitScript.text = `
        console.log("[transparently_wall_placeholder::perform] inside of script tag")
        if (!document.querySelector('[data-gjs-type]')) {
          const url = new URL(window.location.origin +'/uikit/class_component')
          url.search = new URLSearchParams({ 
            name: "Transparently::Wall::Component",
            external_id: "${this.campaignIdValue}"
          })
          const response = fetch(url)
                .then(response => response.text())
                .then((markup) => {
                  const transparently = document.querySelector('[data-controller=transparently-wall-placeholder]')
                  transparently.innerHTML = markup
                })
           
          
        }
      `
      if (this.placeholderTarget) {
        this.placeholderTarget.innerHTML = ""
        this.placeholderTarget.insertAdjacentElement(
          "afterend",
          transparentlyWallInitScript
        )
      }
    } catch (e) {
      console.log("e", e)
    }
  }
}
