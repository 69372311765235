import ApplicationController from "@/controllers/application_controller"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css" 

export default class extends ApplicationController {

  static values = { content: String, contentObject: Array, placement: String}

  initialize() {
    this.content
    this.placement = 'top'
    if (this.hasPlacementValue) this.placement = this.placementValue
    if (this.hasContentValue) {
      this.content = this.contentValue
    } else if (this.hasContentObjectValue) {
      let items = ''
      const contentItems = this.contentObjectValue
      contentItems.map( item => {
        items += `
          <h2 class="text-[10px] font-bold text-gray-500 leading-tight mt-2">${item.title}</h2>
          <div class="mt-1 text-[10px] text-gray-500 leading-tight">${item.desc}</div>
        `
      })
      console.log('items:', items)
      this.content = `
        <div class="rounded-lg bg-white p-4 shadow-lg w-96 max-w-[90vw]">
          ${items}
        </div>
      `
    }
  }

  connect() {
    const toolTip = tippy(this.element, {
      interactive: true,
      placement: this.placement,
      arrow: false,
      theme: 'light',
      animation: "fade",
      content: this.content,
      trigger: "click",
      allowHTML: true,
    })
  }
}


