import ApplicationController from "@/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ['trigger', 'modal', 'layout']
  static classes = [ "hidden" ]
  initialize(){
    console.log('[ModalController::initialize]', true)
    this.isActive = false
    this.currentModal = null
  }

  connect(){
    console.log('[ModalController::connect]', true)
  }

  toggleOpen = (e) => {
    if(e && !this.currentModal) {
      const triggerData = e.currentTarget.dataset
      this.currentModal = this.currentModalTarget(triggerData.modal)
    }
    if (this.currentModal) {
      this.layoutTarget.classList.toggle(this.hiddenClass)
      this.currentModal.classList.toggle(this.hiddenClass)
      if (this.isActive) this.currentModal = null
      this.isActive = !this.isActive
    }
  }

  setCurrentModal = (trigger) => {
    this.currentModal = this.currentModalTarget(trigger)
  }

  currentModalTarget(triggerKey) {
    return this.modalTargets.find( m => m.dataset.modal === triggerKey )
  }

}